import API from "@/utils/api";
import date from 'quasar/src/utils/date.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;
import * as iconv from "iconv-lite";

export default function dataFunctions(method, loading, loadingButton) {

	async function requestTableData(props, tableData) {
		const {page, rowsPerPage, sortBy, descending} = props.pagination
		const filter = props.filter
		loading.value = true
		await API.get(`/api/v1/${method}/`, {
			params: {
				_page: page,
				_limit: rowsPerPage,
				_filter: filter,
				_sortby: sortBy,
				_sortDirection: descending ? 'DESC' : 'ASC',
				_filterColumns: tableData.columns.filter(a => {
					if (a.searchable!==false) return a.name;
				}).map(a=>a.name).toString()
			},
			// validateStatus: function (status) {
			// 	return status < 400; // Resolve only if the status code is less than 500
			// }
		}).then((r) => {
			tableData.pagination.value.rowsNumber = r.headers.__count
			tableData.pagination.value.page = page
			tableData.pagination.value.sortBy = sortBy
			tableData.pagination.value.descending = descending
			tableData.pagination.value.rowsPerPage = rowsPerPage

			tableData.rows.value = r.data

			// handle sortBy
			if (sortBy) {

				const sortFn = sortBy === 'desc'
					? (descending
							? (a, b) => (a.name > b.name ? -1 : a.name < b.name ? 1 : 0)
							: (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
					)
					: (descending
							? (a, b) => (parseFloat(b[sortBy]) - parseFloat(a[sortBy]))
							: (a, b) => (parseFloat(a[sortBy]) - parseFloat(b[sortBy]))
					)
				tableData.rows.value.sort(sortFn)
			}
			loading.value = false
		})
			.catch((reason) => {
				iconv.skipDecodeWarning = true;
				reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
				Notify.create({color: 'negative',message: `${reason.response.status}: ${reason.response.statusText}`})
				loading.value = false
			})

	}

	// Функция обновления одной записи
	async function changeValue(record, field, scope) {
		let value = (typeof scope == "object") ? scope.value : scope
		try {
			loading.value = true
			const response = await API.put(`/api/v1/${method}/`, {
				id: record.id,
				[field]: value,
			})
			if (typeof scope == "object") scope.set()
			// обновляем все поля на фронте, которые могли прийти с сервера
			for (const rec in record) {
				record[rec] = response.data[rec]
			}
			iconv.skipDecodeWarning = true;
			response.headers.__message = iconv.decode(response.headers.__message, 'utf8');
			Notify.create({color: 'positive', message: response.headers.__message})
			loading.value = false
		} catch (reason) {
			console.log(reason)
			iconv.skipDecodeWarning = true;
			reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
			Notify.create({color: 'negative',message: `${reason.response.status}: ${reason.response.statusText}`})
			if (typeof scope == "object") scope.cancel()
		} finally {
			loading.value = false
		}
	}

	// Функция для обновления таблицы.
	// В эту функцию эмитятся данные
	function changeData(record, rows) {
		let arrIndex = Array.prototype.findIndex.call(rows, (x) => x.id === record.id)
		// если в существующем массиве данных не найден полученный ID, добавляем в массив
		arrIndex === -1 ? rows.push(record) : rows[arrIndex] = record
	}

	// установить в поле текущую дату
	async function setNow(record, field) {
		const timeStamp = Date.now()
		record.visit_date = date.formatDate(timeStamp, 'YYYY-MM-DD HH:mm:ss')
		try {
			loading.value = true
			// scope.hide()
			const response = await API.put(`/api/v1/${method}/`, {
				id: record.id,
				[field]: record.visit_date,
			})
			// обновляем все поля на фронте, которые могли прийти с сервера
			for (const rec in record) {
				record[rec] = response.data.data[rec]
			}
			loading.value = false
		} catch (e) {
			console.error(e)
		} finally {
			loading.value = false
		}
	}

	function confirmDelete(record, rows, tableData) {
		Dialog.create({
			title: 'Удаление',
			message: `Удаляем ${record.name} [${record.id}] ?`,
			cancel: true,
			persistent: false
		}).onOk(async () => {
			try {
				loading.value = true
				loadingButton.value[record.id] = true
				const response = await API.delete(`/api/v1/${method}/`, {
					data: {
						id: record.id
					}
				})
				let arrIndex = Array.prototype.findIndex.call(rows, (x) => x.id === record.id)
				rows.splice(arrIndex, 1)
				tableData.pagination.rowsNumber--

				iconv.skipDecodeWarning = true;
				response.headers.__message = iconv.decode(response.headers.__message, 'utf8');
				Notify.create({color: 'positive', message: response.headers.__message})
				loading.value = false
			}  catch (reason) {
				iconv.skipDecodeWarning = true;
				reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
				Notify.create({color: 'negative',message: `${reason.response.status}: ${reason.response.statusText}`})
				loadingButton.value[record.id] = false
			} finally {
				loading.value = false
				loadingButton.value[record.id] = false
			}
		}).onCancel(() => {
			loadingButton.value[record.id] = false
			// console.log('>>>> Cancel')
		}).onDismiss(() => {
			loadingButton.value[record.id] = false
			// console.log('I am triggered on both OK and Cancel')
		})
	}

	return {
		requestTableData,
		changeValue,
		changeData,
		setNow,
		confirmDelete
	}
}
