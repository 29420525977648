<template>
  <q-input
      v-if="!column.type"
      autofocus
      dense
      counter
      v-model="scope.value"
      :model-value="scope.value"
      :hint=column.label
      :mask=column.validation.mask
      :reverse-fill-mask=column.validation.reverseFillMask
      :unmasked-value=column.validation.unmasked
      @keyup.enter=change(scope)
      style="width: 250px"
      :rules="[
            column.validation.rule ? val => scope.validate(val) || column.validation.error : null,
            column.required ? val => !!val || 'Field is required' : null
          ]"
  >
    <template v-slot:after>
      <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>

      <q-btn
          flat dense color="positive" icon="check_circle"
          @click.stop.prevent=change(scope)
          :disable="(column.validation.rule && scope.validate(scope.value) === false) || scope.initialValue === scope.value"
      />
    </template>
  </q-input>
  <div v-if="column.type === 'date'"
       class="q-pa-md">
    <div class="q-gutter-md row items-start">
      <q-input
          v-model="scope.value"
          autofocus
          @keyup.enter=change(scope)
          mask="####-##-## ##:##:00">
        <template v-slot:prepend>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="proxy">
              <q-date
                  v-model="scope.value"
                  mask="YYYY-MM-DD HH:mm"
                  @input="this.$refs.proxy.hide()"
              >
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat/>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>

        <template v-slot:append>
          <q-icon name="access_time" class="cursor-pointer">
            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
              <q-time v-model="scope.value" :model-value="scope.value" mask="YYYY-MM-DD HH:mm" format24h>
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat/>
                </div>
              </q-time>
            </q-popup-proxy>
          </q-icon>
        </template>
        <template v-slot:after>
          <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>

          <q-btn
              flat dense color="positive" icon="check_circle"
              @click.stop.prevent=change(scope)
              :disable="(column.validation.rule && scope.validate(scope.value) === false) || scope.initialValue === scope.value"
          />
        </template>
      </q-input>
    </div>
  </div>
  <q-select
      v-if="column.type === 'autocomplete'"
      ref="select"
      dense
      options-dense
      v-model="scope.value"
      :hint=column.label
      label="Допускается вводить новые значения"
      :mask=column.validation.mask
      :unmasked-value=column.validation.unmasked
      :rules="[
            column.validation.rule ? val => scope.validate(val) || column.validation.error : null,
            column.required ? val => !!val || 'Field is required' : null
          ]"
      use-input
      fill-input
      hide-selected
      :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
      input-debounce="0"
      :options=filterOptions
      @new-value=createValue
      @input-value=changeValue
      @keyup.enter=change(scope)
      @filter=filterFn
      counter
      emit-value
      map-options
  >
    <template v-slot:after>
      <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>
      <q-btn
          flat dense color="positive" icon="check_circle"
          @click.stop.prevent=change(scope)
          :disable="(column.validation.rule && scope.validate(scope.value) === false) || scope.initialValue === scope.value"
      />
    </template>
  </q-select>
  <q-select
      v-if="column.type === 'select'"
      ref="select"
      dense
      options-dense
      v-model="scope.value"
      :multiple=column.multiple
      use-chips
      :hint=column.label
      :rules="[
            column.validation.rule ? val => scope.validate(val) || column.validation.error : null,
            column.required ? val => !!val || 'Field is required' : null
          ]"
      :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
      input-debounce=0
      :options=filterOptions
      @input-value="()=>{if (!column.multiple) changeValue}"
      @filter=filterFn
      use-input
      fill-input
      :hide-selected=!column.multiple
      counter
      emit-value
      map-options
  >
    <template v-slot:after>
      <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>
      <q-btn
          flat dense color="positive" icon="check_circle"
          @click.stop.prevent=change(scope)
          :disable="(column.validation.rule && scope.validate(scope.value) === false) || scope.initialValue === scope.value"
      />
    </template>
  </q-select>
  <div v-if="column.type === 'list'">
    <div class="row items-center" v-for="(element, i) in scope.value">
      <div class="col" :class="{deleted: !scope.value[i].active}">
        <q-select
            ref="select"
            dense
            options-dense
            :disabled="element.active === 0"
            v-model=scope.value[i]
            map-options
            emit-value
            use-input
            input-debounce=0
            option-value=id
            option-label=name
            :options=filterOptions
            @update:model-value="(val)=>changeValueOfListType(val, i, scope)"
            @filter=filterFn
        />
      </div>
      <div class="col" :class="{deleted: !scope.value[i].active}">
        <q-input dense v-model="scope.value[i].formula"/>
      </div>
      <div class="col-1 q-pa-none q-my-none">
        <q-btn flat dense size="xs" @click="deleteListElement(scope.value[i])">
          <q-icon name="delete"/>
        </q-btn>
      </div>
    </div>


    <q-btn flat dense color="positive" icon="add_circle" @click.stop.prevent="addListElement"/>
    <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>
    <q-btn
        flat dense color="positive" icon="check_circle"
        @click.stop.prevent=emitListType(scope)
        :disable="scope.initialValue === scope.value"
    />


  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref, toRaw} from 'vue'
import API from "@/utils/api";
import {QSelect} from "quasar";

const props = defineProps({
  scope: Object,
  column: {},
  modelPage: String,
  hint: String,
  validationMessage: String,
  mask: String,
  reverseFillMask: Boolean,
  unmasked: Boolean,
  type: String
})
const dropDowns = ref()
const select = ref()

const model = ref(null)
const filterOptions = ref()

function addListElement() {
  const newElement = {table_id: 0, id: 0, name: '', formula: '', active: 1}
  props.scope.value.push(newElement)
  props.scope.initialValue.push(newElement)
}

function deleteListElement(element) {
  element.active = !element.active
}

function createValue(val, done) {
  // Calling done(var) when new-value-mode is not set or "add", or done(var, "add") adds "var" content to the model
  // and it resets the input textbox to empty string
  // ----
  // Calling done(var) when new-value-mode is "add-unique", or done(var, "add-unique") adds "var" content to the model
  // only if is not already set
  // and it resets the input textbox to empty string
  // ----
  // Calling done(var) when new-value-mode is "toggle", or done(var, "toggle") toggles the model with "var" content
  // (adds to model if not already in the model, removes from model if already has it)
  // and it resets the input textbox to empty string
  // ----
  // If "var" content is undefined/null, then it doesn't tampers with the model
  // and only resets the input textbox to empty string

  if (val.length > 0) {
    if (!dropDowns.value.includes(val)) {
      dropDowns.value.push(val)
    }
    done(val, 'toggle')
  }
}

// функция, срабатывающая при выборе значения у элемента вложенного списка
function changeValueOfListType(val, i, scope) {
  let tempValues = {...scope.initialValue} // Изначальные значения скоупа
  let obj = dropDowns.value.filter(e => e.id === val)[0] // новое значение объекта из выпадающего списка
  for (const key in obj) {
    tempValues[i][key] = obj[key]
  }

  scope.value[i] = tempValues[i]
}

function changeValue(val) {
  props.scope.value = val
}

function filterFn(val, update) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value
        } else {
          const needle = val.toString().toLowerCase()
          if (props.column.type === 'list') {
            filterOptions.value = dropDowns.value.filter(
                v => v.name.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value.filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}

const emit = defineEmits({
  changedValue: null
})

function emitListType(scope) {
  if (scope.initialValue !== scope.value)
    emit('changedValue', scope)
}

function change(scope) {
  //TODO
  if ((props.column.validation.rule && scope.validate(scope.value) !== false) || scope.initialValue !== scope.value)
    emit('changedValue', scope)
}

async function fetchDropdowns() {
  let params = {}
  if (props.column.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: props.column.name,
      autocomplete: true
    }
  if (props.column.type === 'select')
    params = {
      table: props.column.table.table,
      value: props.column.table.value,
      label: props.column.table.label,
      filter: props.column.table.filter,
      autocomplete: false
    }
  if (props.column.type === 'list')
    params = {
      table: props.column.table,
      field: 'name',
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}

onMounted(() => {
  if (['autocomplete', 'select', 'list'].includes(props.column.type))
    fetchDropdowns()
        .then((a) => dropDowns.value = a)
})

</script>
<style>
.deleted {
  text-decoration: line-through;
}
</style>
