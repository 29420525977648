import {createApp, ref} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from '@/quasar-user-options'
import axios from "axios";


axios.defaults.headers.common['Authorization'] = localStorage.access_token // for all requests
axios.interceptors.response.use(config => {
	return config
}, error => {
	console.log(error.response.status)
	if (error.response.status === 401)
		router.push('/login')
	return error
})

const app = createApp(App)
	.use(Quasar, quasarUserOptions)
	.use(store)
	.use(router)

	app.provide('BINOM_URL','https://jokertrk.com/385id.php')
	app.mount('#app')
