<template>
  <q-page class="full-width row wrap justify-center items-start content-start">
    <div class="column q-pa-lg">
      <div class="row">
        <q-card square class="shadow-24" style="width:300px;height:350px;">
          <q-card-section class="bg-primary">
            <h4 class="text-h5 text-white q-my-xs">Вход</h4>
          </q-card-section>
          <form class="q-px-sm q-pt-xs" @submit.prevent.stop="onSubmit">
            <q-card-section>

              <q-input square clearable v-model="login" label="Логин">
                <template v-slot:prepend>
                  <q-icon name="email"/>
                </template>
              </q-input>
              <q-input square clearable type="password" v-model="password" label="Пароль">
                <template v-slot:prepend>
                  <q-icon name="lock"/>
                </template>
              </q-input>

            </q-card-section>
            <q-card-actions class="q-px-lg">
              <q-btn :loading="loading" unelevated size="lg" color="purple-4" class="full-width text-white" label="Войти" type="submit"/>
            </q-card-actions>
          </form>
          <small>{{error}}</small>
        </q-card>
      </div>
    </div>
  </q-page>
</template>


<script>
import {onMounted, ref} from 'vue'
import API from '@/utils/api'
import router from "@/router";
import store from "@/store";
import {useStore} from "vuex";

async function onSubmit() {
  let nextHop = router.currentRoute.value.query._u ? router.currentRoute.value.query._u : '/'
  try {
    this.error = ''
    this.loading = true
    const response = await API.post('/api/v1/auth/', {
      login: this.login,
      password: this.password,
    })
    localStorage.user = JSON.stringify(response.data)
    store.commit('setUser',response.data)
    router.push(nextHop)
  } catch (e) {
    console.log(e)
    this.error = e.response.data
    this.loading = false
  } finally {
    this.loading = false
  }
}



export default {
  setup() {
    const store = useStore()
    store.commit('logout')
    return {
      login: ref(''),
      password: ref(''),
      loading: ref(false),
      error: ref(''),
      onSubmit,
      onMounted,
      API,
      store
    }
  }
}
</script>

<style>
</style>
