<template>
  <div class="q-pa-md">
    <div class="row items-center q-gutter-sm q-mb-md">
      <q-btn size="sm"
             @click="currentIdEdit=0; showEditDialog=true; clone=false; ">
        <q-icon name="add"/>
      </q-btn>
    </div>
    <q-table
        :dense="$q.screen.gt.md"
        ref="tableRef"
        title="Сайты"
        :rows="rows"
        :columns="visibleColumns"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        @request="requestData"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Поиск">
          <template v-slot:append>
            <q-icon name="search"/>
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="column in visibleColumns" :key="column.id" class="text-left">

            <div v-if="column.name === 'site_ips'">
              <ul class="q-my-none">
                <li v-for="li in props.row[column.name]">
                  {{ li.label }}
                </li>
              </ul>
            </div>

            <div v-else-if="column.name === 'name'">
              <div>
                {{ props.row[column.name] }}
                <a v-if="props.row['binom_id']" :href="BINOM_URL+'?page=Landing_page&action=edit&id='+props.row['binom_id']" target="_blank" @click.stop="">
                  <q-badge color="green">{{ props.row['binom_id'] }}</q-badge>
                </a>
                <a :href="props.row['type']+'/'+props.row['folder']" @click.stop="" target="_blank"><q-icon name="open_in_new"/></a>
              </div>
            </div>
            <div v-else>{{ props.row[column.name] }}</div>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="downloadSite(props.row)"
                :loading="loadingButton[props.row.id]">
              <q-tooltip transition-show="scale" transition-hide="scale">Скачать</q-tooltip>
              <q-icon name="download"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=props.row.id; currentEditingRecord=props.row; showUploadFileDialog=true"
                :loading="loadingButton[props.row.id]">
              <q-tooltip transition-show="scale" transition-hide="scale">Добавить файл</q-tooltip>
              <q-icon name="upload_file"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=0; currentEditingRecord=props.row; clone=true; showEditDialog=true"
                :loading="loadingButton[props.row.id]">
              <q-tooltip transition-show="scale" transition-hide="scale">Дублировать</q-tooltip>
              <q-icon name="folder_copy"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="confirmDelete(props.row,rows,tableRef)"
                :loading="loadingButton[props.row.id]">
              <q-tooltip transition-show="scale" transition-hide="scale">Удалить</q-tooltip>
              <q-icon name="delete"/>
            </q-btn>

            <q-popup-edit v-if="column.editable" v-model="props.row[column.name]" v-slot="scope"
                          :validate="val => column.validation.rule(val)">
              <Popupedit
                  v-bind:scope="scope"
                  :column=column
                  :modelPage=modelPage
                  @changedValue="changeValue(props.row,column.name,$event)"
              />
            </q-popup-edit>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="showEditDialog">
    <ModalEditIdPage
        :id=currentIdEdit
        :columns="columns"
        :currentEditingRecord=currentEditingRecord
        :clone=clone
        :showEditDialog=showEditDialog
        :modelPage=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showEditDialog = false"
    ></ModalEditIdPage>
  </q-dialog>
  <q-dialog v-model="showUploadFileDialog">
    <ModalUploadFilePage
        :id=currentIdEdit
        :columns="columns"
        :currentEditingRecord=currentEditingRecord
        :clone=clone
        :showEditDialog=showUploadFileDialog
        :modelPage=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showUploadFileDialog = false"
    ></ModalUploadFilePage>
  </q-dialog>

</template>
<script>
// для использования в формах редактирования

//export default [validation]
</script>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import Popupedit from "@/components/Popupedit";
import ModalEditIdPage from "@/pages/singlePages/SingleSitePage";
import ModalUploadFilePage from "@/pages/singlePages/UploadFilePage";
import dataFunctions from "@/utils/dataFunctions"
import API from "@/utils/api";
import * as iconv from "iconv-lite";
import {Notify} from "quasar";
const BINOM_URL=inject('BINOM_URL')
const columns = [
  {
    name: 'id',
    required: true,
    label: 'ID',
    align: 'left',
    field: row => row.id,
    format: val => `${val}`,
    sortable: true
  },
  { name: 'file', label: 'Файл', field: 'file', align: 'left', sortable: true, editable: false, addable: true, visible: false, searchable: false, type: 'file',
    validation: {
      error: 'Введите корректный файл',
      rule: v => /^[a-zа-яё\d_\-\s]{2,15}$/i.test(v)
    }
  },
  { name: 'name', label: 'Название', field: 'name', align: 'left', sortable: true, editable: true, addable: true,
    validation: {
      error: 'Введите корректное название сайта',
      rule: v => /^(.*){2,35}$/i.test(v)
    }
  },
  { name: 'folder', label: 'Каталог', field: 'folder', align: 'left', sortable: true, addable: true,
    validation: {
      error: 'Введите коррректный каталог',
      rule: v => /^[a-zA-Z\d_\-]{1,35}$/i.test(v)
    }
  },
  { name: 'type', label: 'Тип', field: 'type', align: 'left', sortable: true, addable: true,  type: 'radio',
    values: [{value:'landings', label:'Landing'}, {value:'whites', label:'White'}, ],
    validation: {
      error: 'Введите коррректный тип сайта',
      rule: v => /^[a-zа-яё\d_\-\s]{2,25}$/i.test(v)
    }
  },
  { name: 'add_to_binom', label: 'Добавлять в бином', field: 'add_to_binom', align: 'left', visible: false, sortable: true, editable: false, addable: true, searchable: false, type: 'checkbox',
    validation: {
      error: '',
      rule: v => /^[^]*?$/i.test(v)
    }
  },
  { name: 'binom_group_id', label: 'Группа Бином', field: 'binom_group_id', value: 'binom_campaign_name', align: 'left', sortable: true, editable: true, addable: true, visible: false,
    type: 'select', table: {table: 'binom.groups', value: 'id', label: 'name', filter: {type: 2}},
    validation: {
      error: 'Введите коррректное название кампании',
    }
  },
  { name: 'site_ips', label: 'IP сайта', field: 'site_ips', align: 'left', sortable: true, editable: true, type: 'select', multiple: true,
    table: {table: 'servers_view', value: 'value', label: 'label'},  addable: true,
    validation: {
      error: 'Выберите IP для загрузки',
      rule: v => /^[^]*?$/i.test(v)
    }
  },
  { name: 'changed', label: 'Изменён', field: 'changed', align: 'left', sortable: true },
  {name: '#', label: '#', field: '#', align: 'left', sortable: false, searchable: false},
]
const modelPage = 'sites'
const tableRef = ref()

const showEditDialog = ref(false)
const showUploadFileDialog = ref(false)

const currentIdEdit = ref(0)
const currentEditingRecord = ref({})
const loading = ref(false)
const rows = ref([])
const filter = ref('')
const uploader = ref(null);
const clone = ref(false)
const loadingButton = ref({})
const pagination = ref({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 10
})
const {requestTableData, changeValue, changeData, confirmDelete} = dataFunctions(modelPage, loading, loadingButton)


async function requestData(props) {
  const tableData = {
    columns, pagination, rows
  }
  await requestTableData(props, tableData)
}

async function downloadSite(record) {

  try {
    loadingButton.value[record.id] = true
    await API.get(`/api/v1/${modelPage}/`, {
      params: {
        id: record.id,
        download: true
      }
    }).then(response => {
      let item = {};
      item.url = response.data.url
      item.label = response.data.filename
      downloadItem(item)
    })

  }
  catch (reason) {
    iconv.skipDecodeWarning = true;
    reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
    Notify.create({color: 'negative',message: `${reason.response.status}: ${reason.response.statusText}`})
    loadingButton.value[record.id] = false
  } finally {
    loadingButton.value[record.id] = false
  }



}

const visibleColumns = computed(()=>{
  return columns.filter(a => a.visible !== false)
})

onMounted(() => {
  // get initial data from server (1st page)
  tableRef.value.requestServerInteraction()
})

function downloadItem ({ url, label }) {
  API.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/zip' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
}
</script>
