<template>
  <div class="row  justify-center content-start">
    <q-card class="my-card">
      <q-card-section>
        <q-form @submit="validate" ref="editForm">
          <q-toolbar-title>{{ record.name }}</q-toolbar-title>
          <div class="card-grid" v-bind:class="{row: editableFields.length > 8}">
            <slot v-for="column of editableFields" :column="column" v-bind:qq="column">
              <q-input
                  v-if="!column.type"
                  :dense="props.id > 0" v-model=record[column.name]
                  :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                  column.required ? val => !!val || 'Field is required' : null]"
                  :mask=column.validation.mask
                  :reverse-fill-mask=column.validation.reverseFillMask
                  :unmasked-value=column.validation.unmasked
                  :label=column.label
              />
              <div v-if="column.type === 'date'" class="q-pa-md">
                <div class="q-gutter-md row items-start">
                  <q-input
                      v-model=record[column.name]
                      autofocus
                      mask="####-##-## ##:##:00"
                      :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                               column.required ? val => !!val || 'Field is required' : null]">
                    <template v-slot:prepend>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="proxy">
                          <q-date
                              v-model=record[column.name]
                              mask="YYYY-MM-DD HH:mm"
                              :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                                       column.required ? val => !!val || 'Field is required' : null]"

                          >
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="primary" flat/>
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>

                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-time v-model=record[column.name] :model-value=record[column.name] mask="YYYY-MM-DD HH:mm"
                                  format24h>
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Close" color="primary" flat/>
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
              <q-select
                  v-if="column.type === 'autocomplete'"
                  ref="select"
                  dense
                  options-dense
                  :label=column.label
                  hint="Допускается вводить новые значения"
                  v-model=record[column.name]
                  :mask=column.validation.mask
                  :unmasked-value=column.validation.unmasked
                  :options=filterOptions
                  @filter="(val, update, abort) => { filterFn(val, update, abort, column) }"
                  @input-value="(val) => {changeValue(val,column.name)}"
                  @new-value="(val, done) => {createValue(val, done, column.name)}"
                  use-input
                  fill-input
                  hide-selected
                  :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
                  input-debounce=0
                  :rules="[
                  val => column.validation.rule(val) || column.validation.error
                  ]"
                  counter
              />
              <q-select
                  v-if="column.type === 'select'"
                  ref="select"
                  dense
                  options-dense
                  v-model=record[column.name]
                  :label=column.label
                  :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
                  :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                           column.required ? val => !!val || 'Field is required' : null]"
                  input-debounce=0
                  use-input
                  fill-input
                  hide-selected
                  emit-value
                  map-options
                  :options=filterOptions
                  @filter="(val, update, abort) => { filterFn(val, update, abort, column) }"
              />
              <q-toggle size="xs"
                        v-if="column.type === 'checkbox'"
                        :true-value="1"
                        :false-value="0"
                        v-model="record[column.name]"
              >{{ column.label }}</q-toggle>
            </slot>
          </div>

        </q-form>
      </q-card-section>
      <q-card-actions>
        <q-btn v-if="props.id > 0" outline @click="validate">Сохранить</q-btn>
        <q-btn v-if="props.id === 0" outline @click="validate">Добавить</q-btn>
        <!--        <q-btn push outline @click="resetForm">Отменить</q-btn>-->
      </q-card-actions>
      <!--    Отображение загрузки в карточке  -->
      <q-inner-loading :showing="loading">
        <q-spinner-orbit size="50px" color="primary"/>
      </q-inner-loading>

    </q-card>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onBeforeUpdate, onMounted, onUpdated, ref, toRaw} from "vue";
import {useRoute} from 'vue-router'
import dataSingleFunctions from "@/utils/dataSingleFunctions";
import API from "@/utils/api";

const route = useRoute()
const record = ref({})
const props = defineProps({
  id: Number,
  currentEditingRecord: ref({}),
  columns: ref({}),
  modelPage: String,
  showEditDialog: ref(false)
})

const loading = ref(false)
const editForm = ref(null)
const emit = defineEmits({
  changedValue: null,
  closeDialog: false
})
const {getSingleData, saveSingleData, addSingleData} = dataSingleFunctions(props.modelPage, loading, emit)

const editableFields = computed(() => {
  return props.id > 0 ? props.columns.filter(a => (a.editable)) : props.columns.filter(a => (a.addable))
})

const select = ref()
const dropDowns = ref({})
const filterOptions = ref()

function filterFn(val, update, abort, column) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value[column.field]
        } else {
          const needle = val.toString().toLowerCase()
          if (column.type === 'list' || column.type === 'autocomplete') {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}
function changeValue(val, field) {
  record.value[field] = val
}
function createValue(val, done, field) {
  if (val.length > 0) {
    if (!dropDowns.value[field].includes(val)) {
      dropDowns.value[field].push(val)
    }
    done(val, 'toggle')
  }
}

onMounted(async () => {
  record.id = route.params.id ? route.params.id : props.id
  if (record.id > 0) await getSingleData(record)
  let fields = editableFields.value.filter(a => (['autocomplete','select'].includes(a.type)))
  for (const field of fields) {
    await fetchDropdowns(field)
        .then(a => dropDowns.value[field.field] = a)
    // .then(() => select.value.refresh())
  }
})


function validate() {
  editForm.value.validate().then(success => {
    if (success) {
      props.id > 0 ? saveSingleData(record) : addSingleData(record)
    } else {
      // Users.rows[1].name = 'aaaaa'
    }
  })
}

async function fetchDropdowns(field) {
  let params = {}
  if (field.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: field.name,
      autocomplete: true
    }
  if (field.type === 'select')
    params = {
      table: field.table.table,
      value: field.table.value,
      label: field.table.label,
      filter: field.table.filter,
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}

</script>
<style lang="scss" scoped>
.my-card {
  width: 600px;
  max-width: 80vw;
}

.card-grid label {
  margin: 3px;
}

/* Если карточка со столбцами, то инпуты на половину ширины*/
.row.card-grid label {
  width: 48%
}
</style>
