import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Users from "@/pages/Users";
import Sites from "@/pages/catalog/Sites";
import Ips from "@/pages/catalog/Ips";
import Domains from "@/pages/catalog/Domains";
import Accounts from "@/pages/catalog/Accounts";
import Images from "@/pages/catalog/Images";
import LoginPage from "@/pages/LoginPage";
import Sandbox from "@/pages/Sandbox";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
	{path: '/users',name: 'users',component: Users},
	{path: '/accounts',name: 'accounts',component: Accounts},
	{path: '/sites',name: 'sites',component: Sites},
	{path: '/ips',name: 'ips',component: Ips},
	{path: '/domains',name: 'domains',component: Domains},
	{path: '/images',name: 'images',component: Images},
	{path: '/sandbox',name: 'sandbox',component: Sandbox},
	{path: '/login',name: 'login',component: LoginPage},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).login : false;

	if (authRequired && !loggedIn) {
		return next('/login');
	}
	next();
})
export default router
