<template>
  <div class="q-pa-md">
    <div class="row items-center q-gutter-sm q-mb-md">
      <q-btn size="sm" @click="currentIdEdit=0; showEditDialog=true"><q-icon name="add"/></q-btn>
      <q-btn size="sm" @click="showMultiAddDialog=true"><q-icon name="queue"/></q-btn>
    </div>
    <q-table
        :dense="$q.screen.gt.md"
        ref="tableRef"
        title="Домены"
        :rows="rows"
        :columns="visibleColumns"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        @request="requestData"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Поиск">
          <template v-slot:append>
            <q-icon name="search"/>
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="column in visibleColumns" :key="column.id" class="text-left">
            <div v-if="column.type === 'checkbox'">
              <q-toggle size="xs"
                        :true-value="1"
                        :false-value="0"
                        v-model="props.row[column.name]"
                        @update:model-value="(val)=>changeValue(props.row,column.name,val)"/>
            </div>
            <div v-else>
              <div v-if="column.name==='name'">
                <q-icon color="green" name="gpp_good" v-if="props.row['certificate_due_date']">
                  <q-tooltip transition-show="scale" transition-hide="scale">
                    <p style='white-space:pre'>{{ props.row["certificate_due_date"] }}</p>
                  </q-tooltip>
                </q-icon>
                {{ column.value ? props.row[column.value] : props.row[column.name] }}<br>
                <small>{{ props.row['ip'] }}</small><br>
                <small>{{ props.row['notes'] ? props.row['notes'] : 'Заметки' }}
                  <q-popup-edit v-model="props.row['notes']" v-slot="scope">
                    <Popupedit
                        v-bind:scope="scope"
                        :column=column
                        :modelPage=modelPage
                        @changedValue="changeValue(props.row,'notes',$event)"
                    />
                  </q-popup-edit>
                </small>
              </div>
              <div v-else>{{ column.value ? props.row[column.value] : props.row[column.name] }}</div>
            </div>
            <q-btn
                v-if="column.name==='#'"
                round dense
                icon="image"
                size="sm"
                @click="domainSettingsDialog(columns.filter(a => a.field === 'images')[0], props.row)"
                   >
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="certificateUpdate(props.row)"
                :loading="loadingButton[props.row.id]"
            >
              <q-icon name="gpp_good"/>
              <q-tooltip transition-show="scale" transition-hide="scale">Установить сертификат</q-tooltip>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=props.row.id; currentEditingRecord=props.row; showEditDialog=true">
              <q-icon name="edit"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="confirmDelete(props.row,rows,tableRef)"
                :loading="loadingButton[props.row.id]">
              <q-icon name="delete"/>
            </q-btn>

            <q-popup-edit v-if="column.editable && column.type !== 'checkbox'" v-model="props.row[column.name]" v-slot="scope"
                          :validate="column.validation.rule ? val => column.validation.rule(val) : (val)=>true ">
              <PopupeditDomainSettings v-if="column.name === 'images'"
                                       v-bind:scope="scope"
                                       :column=column
                                       :modelPage=modelPage
                                       :domain="props.row['name']"
                                       @changedValue="changeValue(props.row,column.name,$event)"
              />
              <PopupeditPixelSettings v-if="column.name === 'pixel'"
                                      v-bind:scope="scope"
                                      :column=column
                                      :modelPage=modelPage
                                      :domain="props.row['name']"
                                      @changedValue="changeValue(props.row,column.name,$event)"
              />
              <Popupedit v-else
                  v-bind:scope="scope"
                  :column=column
                  :modelPage=modelPage
                  @changedValue="changeValue(props.row,column.name,$event)"
              />
            </q-popup-edit>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="showEditDialog">
    <ModalEditIdPage
        :id=currentIdEdit
        :current-editing-user=currentEditingRecord
        :columns="columns"
        :showEditDialog=showEditDialog
        :modelPage=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showEditDialog = false"
    ></ModalEditIdPage>
  </q-dialog>

  <q-dialog v-model="showAlertDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none" style="white-space: pre;">
        <small>{{ alertDialogMessage }}</small>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showMultiAddDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">Adding multiple domains</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-select
            v-model=multiDomains
            dense
            options-dense
            use-input
            use-chips
            multiple
            hide-dropdown-icon
            input-debounce="0"
            @new-value=createValueDomain
            style="width: 500px"
        >
          <template v-slot:selected-item="scope">
            <q-chip
                removable
                dense
                @remove="scope.removeAtIndex(scope.index)"
                :tabindex="scope.tabindex"
                square
                :color=scope.opt.color
                text-color="white"
                class="q-my-none q-ml-xs q-mr-none"
            >
              {{ scope.opt.value }}
            </q-chip>
          </template>
        </q-select>
        <q-select
            label="Выбрать баера"
            dense
            options-dense
            v-model=multiDomainsOwner
            emit-value
            map-options
            :options="dropDowns['owner']"
        />
      </q-card-section>
      <q-card-section>
        <div class="bg-red" v-for="(domainError, i) of multiDomainsError">
          {{ i }}: <small>{{domainError}}</small>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" @click="addMultipleDomains" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showDomainSettingsDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">Editing domain settings</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <PopupeditDomainSettings :column=domainSettingsColumn
                                 :domain="domainSettingsRow['name']"

        />
      </q-card-section>
    </q-card>
  </q-dialog>

</template>
<script>
// для использования в формах редактирования

//export default [validation]
</script>
<script setup>
import {computed, onMounted, ref} from 'vue'
import Popupedit from "@/components/Popupedit";
import PopupeditDomainSettings from "@/components/PopupeditDomainSettings";
import PopupeditPixelSettings from "@/components/PopupeditPixelSettings";
import ModalEditIdPage from "@/pages/singlePages/SingleIdPage";
import dataFunctions from "@/utils/dataFunctions"
import API from "@/utils/api";
import * as iconv from "iconv-lite";
import {Notify} from "quasar";

const columns = [
  { name: 'name', label: 'Название', field: 'name', align: 'left', sortable: true, required: true, editable: false, addable: true, visible: true,
    validation: {
      error: 'Введите коррректное название',
    }
  },
  { name: 'binom_campaign_id', label: 'ID кампании Б.', field: 'binom_campaign_id', value: 'binom_campaign_name', align: 'left', sortable: true, editable: true, addable: true, visible: true,
    type: 'select', table: {table: 'binom.campaigns', value: 'id', label: 'name'},
    validation: {
      error: 'Введите корректное название кампании',
    }
  },
  { name: 'images', label: 'Картинки', field: 'images', align: 'left', sortable: false, editable: true, addable: true, visible: false,
    type: 'list', table: {table: 'images', value: 'name', label: 'name'},
    validation: {
      rule: v => /^(.*){2,15}$/i.test(v),
      error: 'Выберите картинки',
    }
  },
  { name: 'cloaka', label: 'Клоака', field: 'cloaka', align: 'left',
    sortable: true, editable: true, addable: true, visible: true,
    type: 'select', table: {table: 'cloaka', value: 'campaign_title', label: 'campaign_title'},
    validation: {
      error: '',
      rule: v => /^(.*){2,15}$/i.test(v)
    }
  },
  { name: 'pixel', label: 'Пиксели', field: 'pixel', align: 'left', sortable: false, editable: true, addable: true, visible: true,
    validation: {
      error: '',
    }
  },
  { name: 'white_dir', label: 'White', field: 'white_dir', align: 'left', sortable: true, editable: true, addable: true, visible: true,
    type: 'select', table: {table: 'sites', value: 'folder', label: 'name', filter: {type: 'whites'}},
    validation: {
      error: 'Введите корректный вайт',
      rule: v => /^(.*)/i.test(v)
    }
  },
  { name: 'owner', label: 'Владелец', field: 'owner', value: 'owner_name', align: 'left',
    sortable: true, editable: true, addable: true, visible: true,
    type: 'select', table: {table: 'users', value: 'login', label: 'name'},
    validation: {
      error: '',
      rule: v => /^(.*){2,15}$/i.test(v)
    }
  },
  { name: 'account', label: 'Аккаунт', field: 'account', align: 'left',
    sortable: true, editable: true, addable: true, visible: true,
    type: 'select', table: {table: 'accounts', value: 'name', label: 'name'},
    validation: {
      error: '',
      rule: v => /^(.*){2,15}$/i.test(v)
    }
  },
  { name: 'changed', label: 'Изменён', field: 'changed', align: 'left', sortable: true },
  {name: '#', label: '#', field: '#', align: 'left', sortable: false, searchable: false},
]
const modelPage = 'domains'
const tableRef = ref()
const showEditDialog = ref(false)
const showAlertDialog = ref(false)
const showMultiAddDialog = ref(false)

const showDomainSettingsDialog = ref(false)
const domainSettingsColumn = ref()
const domainSettingsRow = ref()

const multiDomains = ref([{value: 'eliteplayparadise.com', color: 'primary'}, {value: 'virtualgamevista.com', color: 'primary'}])
const multiDomainsOwner = ref()
const multiDomainsError = ref({})
const dropDowns = ref({})
const alertDialogMessage = ref('')
const currentIdEdit = ref(1)
const currentEditingRecord = ref({})
const loading = ref(false)
const loadingButton = ref({})
const rows = ref([])
const filter = ref('')
const pagination = ref({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 10
})
const {requestTableData, changeValue, changeData, confirmDelete} = dataFunctions(modelPage, loading, loadingButton )

function filterFn(val, update, abort, column) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value[column.field]
        } else {
          const needle = val.toString().toLowerCase()
          if (column.type === 'list' || column.type === 'autocomplete') {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}
async function requestData(props) {
  const tableData = {
    columns, pagination, rows
  }
  await requestTableData(props, tableData)
}

async function certificateUpdate(record) {
  try {
    loadingButton.value[record.id] = true
    loading.value = true
    // scope.hide()
    const response = await API.put(`/api/v1/domains/`, {
      id: record.id,
      certificate_update: true,
    })
    // обновляем все поля на фронте, которые могли прийти с сервера
    for (const rec in record) {
      record[rec] = response.data[rec]
    }
    loadingButton.value[record.id] = false
    loading.value = false
  } catch (reason) {
    iconv.skipDecodeWarning = true;
    reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
    Notify.create({color: 'negative', multiLine: true, message: `${reason.response.status}: ${reason.response.statusText}`})
    //emit('closeDialog', true)
    alertDialogMessage.value = reason.response.data.error
    showAlertDialog.value = true
    loadingButton.value[record.id] = false
    loading.value = false
  } finally {
    loadingButton.value[record.id] = false
    loading.value = false
  }
}

const visibleColumns = computed(()=>{
  return columns.filter(a => a.visible !== false)
})
function createValueDomain(val, done) {
  let regex = /(?:[\w-]+\.)+[\w-]+/
  if (val.length > 0) {
    const modelValue = (multiDomains.value || []).slice()
    val
        .split(/[\s,;|]+/)
        .map(v => v.trim())
        .filter(v => v.length > 0)
        .forEach(v => {
          if (regex.exec(v)) {
            if (modelValue.includes(regex.exec(v)[0]) === false) {
              modelValue.push({value: regex.exec(v)[0], color: 'primary'})
            }
          }
        })
    done(null)
    multiDomains.value = modelValue
  }
}
async function addMultipleDomains() {
  try {
    loading.value = true
    // scope.hide()
    const response = await API.post(`/api/v1/domains/mass.php`, {
      domains: multiDomains.value.map(d=>d.value),
      owner: multiDomainsOwner.value
    })
    multiDomainsError.value = response.data.error
    for (let domainError in response.data.error) {
      multiDomains.value.map(d=>{
        if (d.value === domainError) d.color = 'red'
      })
    }
    for (let domainSuccess in response.data.data) {
      multiDomains.value.map(d=>{
        if (d.value === domainSuccess) d.color = 'green'
      })
      rows.value.push(response.data.data[domainSuccess])
    }
    // обновляем все поля на фронте, которые могли прийти с сервера
    // for (const rec in record) {
    //   record[rec] = response.data[rec]
    // }
    loading.value = false
  } catch (reason) {
    iconv.skipDecodeWarning = true;
    reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
    Notify.create({color: 'negative', multiLine: true, message: `${reason.response.status}: ${reason.response.statusText}`})
    //emit('closeDialog', true)
    alertDialogMessage.value = reason.response.data.error
    if (reason.response.data.error) showAlertDialog.value = true
    loading.value = false
  } finally {
    loading.value = false
  }
}

function domainSettingsDialog(column, row) {
  showDomainSettingsDialog.value = true
  domainSettingsColumn.value = column
  domainSettingsRow.value = row
}

onMounted(async () => {
  // get initial data from server (1st page)
  tableRef.value.requestServerInteraction()

  let owner = columns.filter(a => {return a.name === 'owner';})[0]
  await fetchDropdowns(owner)
      .then(a => dropDowns.value['owner'] = a)
})

async function fetchDropdowns(field) {
  let params = {}
  if (field.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: field.name,
      autocomplete: true
    }
  if (field.type === 'select')
    params = {
      table: field.table.table,
      value: field.table.value,
      label: field.table.label,
      filter: field.table.filter,
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}
</script>
