<template>
  <div class="row  justify-center content-start">
    <q-card class="my-card">
      <q-card-section>
        <q-form @submit="validate" ref="editForm">
          <q-toolbar-title>{{ record.name }}</q-toolbar-title>
          <div class="card-grid" v-bind:class="{row: editableFields.length > 8}">
              <q-uploader
                  style="width: 100%"
                  :ref="(el)=> {itemRefs['uploader'] = el}"
                  url="/api/v1/sites/files.php"
                  :headers="[{name: 'Authorization', value: access_token}]"
                  auto-expand
                  hide-upload-btn
                  accept=".jpg"
                  :form-fields=formFields
                  field-name="File"
                  @added = "(file) => {}"
                  @failed = "(r) => uploaded(r)"
                  @uploaded = "(r) => uploaded(r)"
              />

          </div>

        </q-form>
      </q-card-section>
      <q-card-section>
        <div v-for="file of uploadedFilesList">
          <a
              target="_blank"
              :href="`/${currentEditingRecord.type}/${currentEditingRecord.folder}/${file.filename}`">
            {{file.filename}}
          </a> {{file.changed}}
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn v-if="props.id > 0" outline @click="validate">Сохранить</q-btn>
        <q-btn v-if="props.id === 0" outline @click="validate">Добавить</q-btn>
<!--                        <q-btn push outline @click="itemRefs['uploader'].upload()">Отменить</q-btn>-->
      </q-card-actions>
      <!--    Отображение загрузки в карточке  -->
      <q-inner-loading :showing="loading">
        <q-spinner-orbit size="50px" color="primary"/>
      </q-inner-loading>

    </q-card>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onBeforeUpdate, onMounted, onUpdated, ref, toRaw} from "vue";
import {useRoute} from 'vue-router'
import dataSingleFunctions from "@/utils/dataSingleFunctions";
import API from "@/utils/api";
import * as iconv from "iconv-lite";
import {Notify} from "quasar";

const itemRefs = ref([])
const route = useRoute()
const record = ref({})
const props = defineProps({
  id: Number,
  currentEditingRecord: ref({}),
  columns: ref({}),
  modelPage: String,
  showEditDialog: ref(false),
  clone: ref(false)
})

const loading = ref(false)
const editForm = ref(null)
const uploadedFilesList = ref({})
const emit = defineEmits({
  changedValue: null,
  closeDialog: false
})
const {getSingleData, saveSingleData, addSingleData} = dataSingleFunctions(props.modelPage, loading, emit)

const editableFields = computed(() => {
  return props.id > 0 ? props.columns.filter(a => (a.editable)) : props.columns.filter(a => (a.addable))
})
const access_token = computed(() => {
  return JSON.parse(localStorage.user).access_token
})
const select = ref()
const dropDowns = ref({})

function changeValue(val, field) {
  record.value[field] = val
}

onMounted(async () => {
  if (props.id === 0 && props.currentEditingRecord) record.value = {...props.currentEditingRecord}
  else {
    record.id = route.params.id ? route.params.id : props.id
    if (record.id > 0) await getSingleData(record)
  }
  await fetchUploadedFiles()
      .then(a => uploadedFilesList.value = a)
})


function validate() {
  editForm.value.validate().then(success => {
    if (success) {
      itemRefs.value['uploader'].upload()
    } else {
      console.warn('Need to validate form')
    }
  })
}

async function fetchUploadedFiles() {
  let params = {id: props.id}
  let response = await API.get(`/api/v1/sites/files.php`, {
    params: params
  })
  return response.data
}

function formFields() {
  return [{name: 'id', value: record.value['id']}]
}

// функция, срабатывающая после загрузки файлов на сервер
function uploaded(response) {
  if (response.xhr.status > 299) {
    iconv.skipDecodeWarning = true
    let statusText = iconv.decode(response.xhr.statusText, 'utf8')
    Notify.create({color: 'negative', message: `${response.xhr.status}: ${statusText}`})
  }
  if (response.xhr.status < 300) {
    emit('changedValue', JSON.parse(response.xhr.response))
    emit('closeDialog', true)
    iconv.skipDecodeWarning = true;
    let __message = iconv.decode(response.xhr.getResponseHeader('__message'), 'utf8');
    Notify.create({color: 'positive', message: __message})
  }
  // emit('changedValue', response.data)
}
</script>
<style lang="scss" scoped>
a, a:visited {
  color: blue;
}

.my-card {
  width: 600px;
  max-width: 80vw;
}

.card-grid label {
  margin: 3px;
}

/* Если карточка со столбцами, то инпуты на половину ширины*/
.row.card-grid label {
  width: 48%
}

</style>
