import API from "@/utils/api";
import Notify from 'quasar/src/plugins/Notify.js';;
import * as iconv from "iconv-lite";

export default function dataSingleFunctions(method, loading, emit) {
	async function getSingleData(record) {
		loading.value = true
		try {
			const response = await API.get(`/api/v1/${method}/` + record.id)
			record.value = response.data
			// this.loading = false
		} catch (reason) {
			iconv.skipDecodeWarning = true;
			reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
			Notify.create({color: 'negative', message: `${reason.response.status}: ${reason.response.statusText}`})
			emit('closeDialog', true)
			loading.value = false
		} finally {
			loading.value = false
		}
	}

	async function saveSingleData(record) {
		loading.value = true
		try {
			const response = await API.put(`/api/v1/${method}/`, record.value)
			emit('changedValue', response.data)
			iconv.skipDecodeWarning = true;
			response.headers.__message = iconv.decode(response.headers.__message, 'utf8');
			Notify.create({color: 'positive', message: response.headers.__message})
		} catch (reason) {
			iconv.skipDecodeWarning = true;
			reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
			Notify.create({color: 'negative', message: `${reason.response.status}: ${reason.response.statusText}`})
			loading.value = false
		} finally {
			loading.value = false
		}
	}

	async function addSingleData(record) {
		loading.value = true
		try {
			const response = await API.post(`/api/v1/${method}/`, record.value)
			emit('changedValue', response.data)
			emit('closeDialog', true)
			iconv.skipDecodeWarning = true;
			response.headers.__message = iconv.decode(response.headers.__message, 'utf8');
			Notify.create({color: 'positive', message: response.headers.__message})
		} catch (reason) {
			iconv.skipDecodeWarning = true;
			reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
			Notify.create({color: 'negative', message: `${reason.response.status}: ${reason.response.statusText}`})
			loading.value = false
		} finally {
			loading.value = false
		}
	}

	return {
		getSingleData,
		saveSingleData,
		addSingleData
	}
}
