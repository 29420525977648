<template>
  <div class="row  justify-center content-start">
    <q-card class="my-card">
      <q-card-section>
        <q-form @submit="validate" ref="editForm">
          <q-toolbar-title>{{ record.name }}</q-toolbar-title>
          <div class="card-grid" v-bind:class="{row: editableFields.length > 8}">
            <slot v-for="column of editableFields" :column="column" v-bind:qq="column">
              <q-input
                  v-if="!column.type"
                  :dense="props.id > 0" v-model=record[column.name]
                  :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                  column.required ? val => !!val || 'Field is required' : null]"
                  :mask=column.validation.mask
                  :reverse-fill-mask=column.validation.reverseFillMask
                  :unmasked-value=column.validation.unmasked
                  :label=column.label
                  counter
              />
              <q-select
                  v-if="column.type === 'autocomplete'"
                  ref="select"
                  dense
                  options-dense
                  :label=column.label
                  hint="Допускается вводить новые значения"
                  v-model=record[column.name]
                  :mask=column.validation.mask
                  :unmasked-value=column.validation.unmasked
                  :options=filterOptions
                  @filter="(val, update, abort) => { filterFn(val, update, abort, column) }"
                  @input-value="(val) => {changeValue(val,column.name)}"
                  @new-value="(val, done) => {createValue(val, done, column.name)}"
                  use-input
                  fill-input
                  hide-selected
                  :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
                  input-debounce=0
                  :rules="[
                  val => column.validation.rule(val) || column.validation.error
                  ]"
                  counter
              />
              <q-select
                  v-if="column.type === 'select' && column.name !== 'binom_group_id'"
                  ref="select"
                  dense
                  options-dense
                  :multiple=column.multiple
                  use-chips
                  v-model=record[column.name]
                  :label=column.label
                  :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
                  :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                           column.required ? val => !!val || 'Field is required' : null]"
                  input-debounce=0
                  use-input
                  fill-input
                  :hide-selected=!column.multiple
                  emit-value
                  map-options
                  :options=filterOptions
                  @filter="(val, update, abort) => { filterFn(val, update, abort, column) }"
              />
              <q-select
                  v-if="column.name === 'binom_group_id' && record['add_to_binom'] && record['type'] === 'landings'"
                  dense
                  options-dense
                  v-model=record[column.name]
                  :label="column.label"
                  :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
                  :rules="[column.validation.rule ? val => column.validation.rule(val) || column.validation.error : null,
                           column.required ? val => !!val || 'Field is required' : null]"
                  input-debounce=0
                  use-input
                  fill-input
                  emit-value
                  map-options
                  hide-selected
                  :options=filterOptions
                  @filter="(val, update, abort) => { filterFn(val, update, abort, column) }"
              />
              <div class="q-gutter-sm"  v-if="column.type === 'radio'">
                <q-radio v-for="col of column.values" v-model=record[column.name] :val=col.value :label=col.label @update:model-value="changeSiteType"/>
              </div>
              <q-uploader
                  style="width: 100%"
                  v-if="column.type === 'file' && !clone"
                  :ref="(el)=> {itemRefs['uploader'] = el}"
                  url="/api/v1/sites/"
                  :headers="[{name: 'Authorization', value: access_token}]"
                  auto-expand
                  hide-upload-btn
                  accept=".zip"
                  :form-fields=formFields
                  :field-name=column.name
                  @added = "(file) => changeFileName(file)"
                  @failed = "(r) => uploaded(r)"
                  @uploaded = "(r) => uploaded(r)"
              />
              <q-toggle size="xs"
                        v-if="column.type === 'checkbox' && column.name === 'add_to_binom' && record['type'] === 'landings'"
                        :true-value="1"
                        :false-value="0"
                        v-model="record[column.name]"
                        :v-model-value="()=>{record['type'] !== 'landings' ? 0 : 1}"
              >{{ column.label }}</q-toggle>
            </slot>
          </div>

        </q-form>
      </q-card-section>
      <q-card-actions>
        <q-btn v-if="props.id > 0" outline @click="validate">Сохранить</q-btn>
        <q-btn v-if="props.id === 0" outline @click="validate">Добавить</q-btn>
<!--                        <q-btn push outline @click="itemRefs['uploader'].upload()">Отменить</q-btn>-->
      </q-card-actions>
      <!--    Отображение загрузки в карточке  -->
      <q-inner-loading :showing="loading">
        <q-spinner-orbit size="50px" color="primary"/>
      </q-inner-loading>

    </q-card>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onBeforeUpdate, onMounted, onUpdated, ref, toRaw} from "vue";
import {useRoute} from 'vue-router'
import dataSingleFunctions from "@/utils/dataSingleFunctions";
import API from "@/utils/api";
import * as iconv from "iconv-lite";
import {Notify} from "quasar";

const itemRefs = ref([])
const route = useRoute()
const record = ref({})
const props = defineProps({
  id: Number,
  currentEditingRecord: ref({}),
  columns: ref({}),
  modelPage: String,
  showEditDialog: ref(false),
  clone: ref(false)
})

const loading = ref(false)
const editForm = ref(null)
const emit = defineEmits({
  changedValue: null,
  closeDialog: false
})
const {getSingleData, saveSingleData, addSingleData} = dataSingleFunctions(props.modelPage, loading, emit)

const editableFields = computed(() => {
  return props.id > 0 ? props.columns.filter(a => (a.editable)) : props.columns.filter(a => (a.addable))
})
const access_token = computed(() => {
  return JSON.parse(localStorage.user).access_token
})
const select = ref()
const dropDowns = ref({})
const filterOptions = ref()

function filterFn(val, update, abort, column) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value[column.field]
        } else {
          const needle = val.toString().toLowerCase()
          if (column.type === 'list' || column.type === 'autocomplete') {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value[column.field].filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}

function changeValue(val, field) {
  record.value[field] = val
}

function createValue(val, done, field) {
  if (val.length > 0) {
    if (!dropDowns.value[field].includes(val)) {
      dropDowns.value[field].push(val)
    }
    done(val, 'toggle')
  }
}

onMounted(async () => {
  if (props.id === 0 && props.currentEditingRecord) record.value = {...props.currentEditingRecord}
  else {
    record.id = route.params.id ? route.params.id : props.id
    if (record.id > 0) await getSingleData(record)
  }
  if (props.clone) {
    record.value.add_to_binom = record.value.binom_id ? 1 : 0
  } else {
    record.value.add_to_binom = 0
    record.value.type = 'whites'
  }


  let fields = editableFields.value.filter(a => (['autocomplete', 'select'].includes(a.type)))
  for (const field of fields) {
    await fetchDropdowns(field)
        .then(a => dropDowns.value[field.field] = a)
    // .then(() => select.value.refresh())
  }
})


function validate() {
  editForm.value.validate().then(success => {
    if (success) {
      // props.id > 0 ? saveSingleData(record) : addSingleData(record)
      props.id > 0 ? saveSingleData(record) :
          props.clone ? addSingleData(record) : itemRefs.value['uploader'].upload()
    } else {
      console.warn('aaaa')
    }
  })
}

async function fetchDropdowns(field) {
  let params = {}
  if (field.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: field.name,
      autocomplete: true
    }
  if (field.type === 'select')
    params = {
      table: field.table.table,
      value: field.table.value,
      label: field.table.label,
      filter: field.table.filter,
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}

// Автозаполнение полей "название" и "каталог" после добавления файла к загрузке
function changeFileName(file) {
  if (file.length === 1) {
    let filename = file[0].name.split('.').slice(0, -1).join('.')

    //TODO сделать сразу проверку на соответствие правилам, описанным в настройках поля
    record.value['name'] = filename
    record.value['folder'] = filename.replace(/[^A-Za-z\d-_]/gi, '')
  }
}

function formFields() {
  return editableFields.value.map(a => {
      return {name: a.name, value: record.value[a.name]}
  })
}

// функция, срабатывающая после загрузки файлов на сервер
function uploaded(response) {
  if (response.xhr.status > 299) {
    iconv.skipDecodeWarning = true
    let statusText = iconv.decode(response.xhr.statusText, 'utf8')
    Notify.create({color: 'negative', message: `${response.xhr.status}: ${statusText}`})
  }
  if (response.xhr.status < 300) {
    emit('changedValue', JSON.parse(response.xhr.response))
    emit('closeDialog', true)
    iconv.skipDecodeWarning = true;
    let __message = iconv.decode(response.xhr.getResponseHeader('__message'), 'utf8');
    Notify.create({color: 'positive', message: __message})
  }
  // emit('changedValue', response.data)
}
// Обработка изменений переключателя типа сайта
function changeSiteType(val) {
  if (val !== 'landings') record.value.add_to_binom = 0
}
</script>
<style lang="scss" scoped>
.my-card {
  width: 600px;
  max-width: 80vw;
}

.card-grid label {
  margin: 3px;
}

/* Если карточка со столбцами, то инпуты на половину ширины*/
.row.card-grid label {
  width: 48%
}

</style>
