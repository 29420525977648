<template>
  <q-layout view="hHh Lpr lff" container style="height: 100vh" class="shadow-2">
    <Navbar v-if="$store.state.user"></Navbar>
    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script setup>
import Navbar from "@/components/Navbar";
import {onMounted} from "vue";
import {useStore} from "vuex";
onMounted(() => {
  const store = useStore()
  store.commit('setUser',JSON.parse(localStorage.getItem('user')))
})
</script>
