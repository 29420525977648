import axios from "axios";
import router from "../router/index"

const api = axios.create()
import store from '@/store'

//start request
api.interceptors.request.use(config => {
	if (localStorage.user && JSON.parse(localStorage.user).access_token) {
		config.headers.authorization = JSON.parse(localStorage.user).access_token
	}

	//Надо возвращать конфиг после его модификации
	return config
}, error => {
	//Этот блок кода срабатывает только тогда, когда ошибка отправки запроса с фронта
	console.log(error)
})
//end request

//start response
api.interceptors.response.use(config => {
		if (localStorage.user && JSON.parse(localStorage.user).access_token) {
			config.headers.authorization = localStorage.access_token
		}

		return config
	}
	, error => {
		//Этот блок кода срабатывает когда прилетает ошибка с бэка
		// console.log(error)
		if (error.response.statusText === 'Token has expired') {
			axios.post('api/auth/refresh', {}, {
				headers: {
					'authorization': localStorage.access_token
				}
			}).then(response => {
				localStorage.access_token = response.data.access_token

				//Делаем повторный запрос на получение данных с новым токеном
				//чтобы вручную не обновлять страницу
				error.config.headers.authorization = response.data.access_token

				return api.request(error.config)
			})
		}

		if (error.response.status === 401) {
			store.commit('logout')
			//localStorage.removeItem('user')
			if (router.currentRoute.value.path !== '/login')
				router.push('/login?_u=' + router.currentRoute.value.path)
		}
		return Promise.reject(error);
	}
)
//end response

export default api
