<template v-slot:default="scope">

  <div class="row items-sm-start" style="width: 300px" v-if="loading && domainSettings.length === 0">
    <q-skeleton class="col-2" :type="QInput" /><q-skeleton class="col" :type="QInput" />
  </div>
  <div class="row items-sm-start" v-if="domainSettings.length > 0" v-for="(element, i) in domainSettings">
    <q-btn flat dense
           v-bind:icon="element.deleted ? 'restore' : 'delete'"
           size="sm"
           @click="deleteListElement(element)"/>
    <q-input
        class="col-1"
        autofocus
        dense
        counter
        :disable=element.deleted
        v-model=element.name
        :model-value=element.name
        :hint=column.label
        :mask=column.validation.mask
        :reverse-fill-mask=column.validation.reverseFillMask
        :unmasked-value=column.validation.unmasked
        @keyup.enter=change(scope)
        style="width: 250px"
    />
    <q-select
        v-model=element.value
        class="col"
        ref="select"
        dense
        options-dense
        map-options
        :disable=element.deleted
        use-input
        hide-selected
        fill-input
        input-debounce=0
        option-value=id
        option-label=name
        :options=filterOptions
        @filter=filterFn
    >
    </q-select>
    <img v-if="element.value" :src=element.value.url style="width: 50px">
  </div>
  <q-btn flat dense color="positive" icon="add_circle" @click.stop.prevent="addListElement"/>
  <q-btn flat dense color="negative" icon="cancel"/>
  <q-btn
      flat dense color="positive" icon="check_circle"
      @click.stop.prevent=change(domainSettings)
      :disable="JSON.stringify(initialDomainSettings) === JSON.stringify(domainSettings)"
  />
  <q-inner-loading :showing="loading">
    <q-spinner-orbit size="50px" color="primary"/>
  </q-inner-loading>
</template>

<script setup>
import {computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref, toRaw} from 'vue'
import API from "@/utils/api";
import {QSelect} from "quasar";

const props = defineProps({
  scope: Object,
  column: {},
  modelPage: String,
  hint: String,
  validationMessage: String,
  mask: String,
  reverseFillMask: Boolean,
  unmasked: Boolean,
  type: String,
  domain: String
})
const dropDowns = ref()
const domainSettings = ref([])
const initialDomainSettings = ref([])
const select = ref()
const loading = ref(false)

const model = ref(null)
const filterOptions = ref()

function addListElement() {
  const newElement = {id: 0, name: '', active: 1, type: props.column.field, domain: props.domain}
  domainSettings.value.push(newElement)
}

function deleteListElement(element) {
  element.deleted = !element.deleted
}

function createValue(val, done) {
  // Calling done(var) when new-value-mode is not set or "add", or done(var, "add") adds "var" content to the model
  // and it resets the input textbox to empty string
  // ----
  // Calling done(var) when new-value-mode is "add-unique", or done(var, "add-unique") adds "var" content to the model
  // only if is not already set
  // and it resets the input textbox to empty string
  // ----
  // Calling done(var) when new-value-mode is "toggle", or done(var, "toggle") toggles the model with "var" content
  // (adds to model if not already in the model, removes from model if already has it)
  // and it resets the input textbox to empty string
  // ----
  // If "var" content is undefined/null, then it doesn't tampers with the model
  // and only resets the input textbox to empty string

  if (val.length > 0) {
    if (!dropDowns.value.includes(val)) {
      dropDowns.value.push(val)
    }
    done(val, 'toggle')
  }
}

// функция, срабатывающая при выборе значения у элемента вложенного списка
function changeValueOfListType(val, i, scope) {
  let tempValues = {...scope.initialValue} // Изначальные значения скоупа
  let obj = dropDowns.value.filter(e => e.id === val)[0] // новое значение объекта из выпадающего списка
  for (const key in obj) {
    tempValues[i][key] = obj[key]
  }

  scope.value[i] = tempValues[i]
}

function changeValue(val) {
  props.scope.value = val
}

function filterFn(val, update) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value
        } else {
          const needle = val.toString().toLowerCase()
          if (props.column.type === 'list') {
            filterOptions.value = dropDowns.value.filter(
                v => v.name.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value.filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}

const emit = defineEmits({
  changedValue: null
})

function filterDropDownsById(id) {
  return dropDowns.value.filter(v => v.id.toString().toLowerCase().indexOf(id) > -1)
}

function emitListType(scope) {
  if (scope.initialValue !== scope.value)
    emit('changedValue', scope)
}

async function change(data) {
  loading.value = true
  let response = await API.post(`/api/v1/domains/settings.php`, {
    settings: data,
    domain: props.domain,
    type: props.column.field
  })
      .then(() => prepareData())
}

async function fetchDropdowns() {
  let params = {}
  if (props.column.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: props.column.name,
      autocomplete: true
    }
  if (props.column.type === 'select')
    params = {
      table: props.column.table.table,
      value: props.column.table.value,
      label: props.column.table.label,
      filter: props.column.table.filter,
      autocomplete: false
    }
  if (props.column.type === 'list')
    params = {
      table: props.column.table.table,
      value: props.column.table.value,
      label: props.column.table.label,
      filter: props.column.table.filter,
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}

async function getDomainsSetings() {
  let params = {
    domain: props.domain,
    type: props.column.field
  }
  let response = await API.get(`/api/v1/domains/settings.php`, {
    params: params
  })
  return response.data
}

function prepareData() {
  loading.value = true
  fetchDropdowns()
      .then((a) => dropDowns.value = a)
      .then(() => getDomainsSetings()
          .then((a) => {
            if (a) domainSettings.value = a
          })
          .then(() => domainSettings.value.forEach((item) => {
            item.value = {...filterDropDownsById(item.value)[0]}
            initialDomainSettings.value.push({...item})
            // props.scope.initialValue = initialDomainSettings.value
          }))
          // .then(() => props.scope.value = domainSettings.value)
          .then(() => loading.value = false))
}

onMounted(() => {
    prepareData()
})

</script>
<style>
.deleted {
  text-decoration: line-through;
}
</style>
