<template>
  <q-select
      :behavior="$q.platform.is.ios === true ? 'dialog' : 'menu'"
      :options=pixelTypeList
      v-model="scope.value.type"
      hint="Type of pixel"
      dense
      options-dense
  />
  <q-input
      dense
      v-model="scope.value.cnv"
      hint="Code for conversion"
  />
  <q-input
      dense
      v-model="scope.value.view"
      hint="Code for view"
  />
  <q-input
      dense
      v-model="scope.value.s2s"
      hint="Code for s2s"
  >
    <template v-slot:after>
      <q-btn flat dense color="negative" icon="cancel" @click.stop.prevent="scope.cancel"/>

      <q-btn
          flat dense color="positive" icon="check_circle"
          @click.stop.prevent=change(scope)
          :disable="JSON.stringify(scope.initialValue) === JSON.stringify(scope.value)"
      />
    </template>
  </q-input>
  <q-inner-loading :showing="loading">
    <q-spinner-orbit size="50px" color="primary"/>
  </q-inner-loading>
</template>


<script setup>
import {computed, defineEmits, defineProps, getCurrentInstance, onMounted, onBeforeMount, ref, toRaw} from 'vue'
import API from "@/utils/api";
import {QSelect} from "quasar";

const props = defineProps({
  scope: Object,
  column: {},
  modelPage: String,
  hint: String,
  validationMessage: String,
  mask: String,
  reverseFillMask: Boolean,
  unmasked: Boolean,
  type: String,
  pixel_type: String,
  pixel_settings: {}
})
const dropDowns = ref()
const select = ref()

const model = ref(null)
const filterOptions = ref()

const pixelTypeList = ['Google', 'Facebook']

function changeValue(val) {
  props.scope.value = val
}

function filterFn(val, update) {
  update(() => {
        if (val === '') {
          filterOptions.value = dropDowns.value
        } else {
          const needle = val.toString().toLowerCase()
          if (props.column.type === 'list') {
            filterOptions.value = dropDowns.value.filter(
                v => v.name.toString().toLowerCase().indexOf(needle) > -1
            )
          } else {
            filterOptions.value = dropDowns.value.filter(
                v => v.label.toString().toLowerCase().indexOf(needle) > -1
            )
          }
        }
      },
      ref => {
        if (val !== '' && ref.options.length > 0) {
          ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
        }
      }
  )
}

const emit = defineEmits({
  changedValue: null
})

function emitListType(scope) {
  if (scope.initialValue !== scope.value)
    emit('changedValue', scope)
}

function change(scope) {
  //TODO
  for (const key in scope.value) {
    if (!scope.value[key]) delete scope.value[key]
  }
  if ((props.column.validation.rule && scope.validate(scope.value) !== false) || scope.initialValue !== scope.value)
    emit('changedValue', scope)
}

async function fetchDropdowns() {
  let params = {}
  if (props.column.type === 'autocomplete')
    params = {
      table: props.modelPage,
      value: props.column.name,
      autocomplete: true
    }
  if (props.column.type === 'select')
    params = {
      table: props.column.table.table,
      value: props.column.table.value,
      label: props.column.table.label,
      filter: props.column.table.filter,
      autocomplete: false
    }
  if (props.column.type === 'list')
    params = {
      table: props.column.table,
      field: 'name',
      autocomplete: false
    }

  let response = await API.get(`/api/v1/dropdowns/`, {
    params: params
  })
  return response.data
}

onBeforeMount(() => {
  // Проверка, если scope пустой
      if (props.scope.value === null) {
        props.scope.value = {}
      }
    }
)

onMounted(() => {
  console.log("Mounted",props.scope)
  if (['autocomplete', 'select', 'list'].includes(props.column.type))
    fetchDropdowns()
        .then((a) => dropDowns.value = a)
})

</script>
<style>
.deleted {
  text-decoration: line-through;
}
</style>
