import script from "./Popupedit.vue?vue&type=script&setup=true&lang=js"
export * from "./Popupedit.vue?vue&type=script&setup=true&lang=js"

import "./Popupedit.vue?vue&type=style&index=0&id=fa87157e&lang=css"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QSelect from 'quasar/src/components/select/QSelect.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QIcon,QPopupProxy,QDate,QTime,QSelect});qInstall(script, 'directives', {ClosePopup});
