import { createStore } from 'vuex'

export default createStore({
  state: {
	  user: Object
  },
  mutations: {
		setUser(state, user) {
			state.user = user
		},
	  logout(state) {
		  localStorage.removeItem('user')
			state.user = null
	  }
  }
})
