<template>
  <div class="q-pa-md">
    <div class="row items-center q-gutter-sm q-mb-md">
      <q-btn size="sm"
             @click="currentIdEdit=0; showEditDialog=true">
        <q-icon name="add"/>
      </q-btn>
    </div>
    <q-table
        :dense="$q.screen.gt.sm"
        ref="tableRef"
        title="Пользователи"
        :rows="rows"
        :columns="visibleColumns"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        @request="requestData"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Поиск">
          <template v-slot:append>
            <q-icon name="search"/>
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="column in visibleColumns" :key="column.id" class="text-left"> {{ props.row[column.name] }}
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=props.row.id; currentEditingRecord=props.row; showEditDialog=true">
              <q-icon name="edit"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="confirmDelete(props.row,rows,tableRef)"
                :loading="loadingButton[props.row.id]">
              <q-icon name="delete"/>
            </q-btn>

            <q-popup-edit v-if="column.editable" v-model="props.row[column.name]" v-slot="scope"
                          :validate="val => column.validation.rule(val)">
              <Popupedit
                  v-bind:scope="scope"
                  :column=column
                  :modelPage=modelPage
                  @changedValue="changeValue(props.row,column.name,$event)"
              />
            </q-popup-edit>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="showEditDialog">
    <ModalEditIdPage
        :id=currentIdEdit
        :current-editing-user="currentEditingRecord"
        :columns="columns"
        :model=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showEditDialog = false"></ModalEditIdPage>
  </q-dialog>
</template>
<script setup>
import {computed, onMounted, provide, ref} from 'vue'
import Popupedit from "@/components/Popupedit";
import ModalEditIdPage from "@/pages/singlePages/SingleIdPage";
import dataFunctions from "@/utils/dataFunctions"

const columns = [
  {
    name: 'id',
    required: true,
    label: 'ID',
    align: 'left',
    field: row => row.id,
    format: val => `${val}`,
    sortable: true
  },
  {name: 'login', label: 'Логин', field: 'login', align: 'left', sortable: true, editable: true,
    validation: {
      error: "Логин начинается с буквы, содержит латинские буквы, цифры и -_. От 3 до 15 символов",
      rule: v => /^[a-zA-Z]{1}[a-zA-Z0-9_-]{2,15}$/.test(v)
    }
  },
  {name: 'name', label: 'Имя', field: 'name', align: 'left', sortable: true, editable: true,
    validation: {
      rule: v => /^[]{1,30}$/.test(v)
    }
  },
  {name: 'email', label: 'Email', field: 'email', align: 'left', sortable: true, editable: true,
    validation: {
      error: 'Введите коррректный email',
      rule: v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)
    }
  },
  {name: 'telegram', label: 'Телеграм', field: 'telegram', align: 'left', sortable: true, editable: true,
    validation: {
      error: "Введите корректный телеграм логин",
      rule: v => /^[a-zA-Z]{1}[\w_]{4,31}$/.test(v)
    }
  },
  {name: 'phone', label: 'Телефон', field: 'phone', align: 'left', sortable: true, editable: true,
    validation: {
      error: 'Введите корректный телефон',
      rule: v => /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(v),
      mask: "+7 (###) ###-####"
    }
  },
  {name: 'role', label: 'Роль', field: 'role', align: 'left', sortable: true, editable: true,
    validation: {
      error: "Введите корректную роль",
      rule: v => /^[a-zA-Z]{1,15}$/.test(v)
    }
  },
  {name: 'team', label: 'Команда', field: 'team', align: 'left', sortable: true, editable: true, type: 'autocomplete',
    validation: {
      error: "Введите корректное название",
      rule: v => /^[a-zA-Zа-яА-Я\d_\-]{1,15}$/.test(v)
    }
  },
  {name: 'visit_date', label: 'Вход', field: 'visit_date', align: 'left', sortable: true},
  {name: 'changed', label: 'Изменён', field: 'changed', align: 'left', sortable: true, visible: false},
  {name: '#', label: '#', field: '#', align: 'left', sortable: false},

  // { name: 'iron', label: 'Iron (%)', field: 'iron', sortable: true, sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) }
]
const modelPage = 'users'
const tableRef = ref()
const showEditDialog = ref(false)
const currentIdEdit = ref(1)
const currentEditingRecord = ref({})
const loading = ref(false)
const loadingButton = ref({})
const rows = ref([])
const filter = ref('')
const pagination = ref({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 10
})
const {requestTableData, changeValue, changeData, confirmDelete} = dataFunctions(modelPage, loading, loadingButton )


async function requestData(props) {
  const tableData = {
    columns, pagination, rows
  }
  await requestTableData(props, tableData)
}

const visibleColumns = computed(()=>{
  return columns.filter(a => a.visible !== false)
})

onMounted(() => {
  // get initial data from server (1st page)
  tableRef.value.requestServerInteraction()
})
</script>
