import script from "./PopupeditPixelSettings.vue?vue&type=script&setup=true&lang=js"
export * from "./PopupeditPixelSettings.vue?vue&type=script&setup=true&lang=js"

import "./PopupeditPixelSettings.vue?vue&type=style&index=0&id=df8858fa&lang=css"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerOrbit from 'quasar/src/components/spinner/QSpinnerOrbit.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QInput,QBtn,QInnerLoading,QSpinnerOrbit});
