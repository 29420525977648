<template>
  <div class="q-pa-md">
    <div class="row items-center q-gutter-sm q-mb-md">
      <q-btn size="sm"
             @click="currentIdEdit=0; showEditDialog=true">
        <q-icon name="add"/>
      </q-btn>
    </div>
    <q-table
        :dense="$q.screen.gt.md"
        ref="tableRef"
        title="IP"
        :rows="rows"
        :columns="visibleColumns"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        @request="requestData"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Поиск">
          <template v-slot:append>
            <q-icon name="search"/>
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="column in visibleColumns" :key="column.id" class="text-left">
            <div v-if="column.name==='ip'">
              {{ props.row[column.name] }}
            </div>
            <div v-else-if="column.type === 'checkbox'">
              <q-toggle size="xs"
                        :true-value="1"
                        :false-value="0"
                        v-model="props.row[column.name]"
                        @update:model-value="(val)=>changeValue(props.row,column.name,val)"/>
            </div>
            <div v-else>{{ props.row[column.name] }}</div>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                v-bind:class="{'text-green':props.row['ssh']===1,'text-red':props.row['ssh']===0}"
                @click="checkSsh(props.row)"
                :loading="loadingButton[props.row.id]"
            >
              <q-icon name="link"/>
              <q-tooltip transition-show="scale" transition-hide="scale">Проверка соединения с сервером</q-tooltip>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=props.row.id; currentEditingRecord=props.row; showEditDialog=true"
                :loading="loadingButton[props.row.id]">
              <q-icon name="edit"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="confirmDelete(props.row,rows,tableRef)"
                :loading="loadingButton[props.row.id]">
              <q-icon name="delete"/>
            </q-btn>

            <q-popup-edit v-if="column.editable && column.type !== 'checkbox'" v-model="props.row[column.name]" v-slot="scope"
                          :validate="val => column.validation.rule(val)">
              <Popupedit
                  v-bind:scope="scope"
                  :column=column
                  :modelPage=modelPage
                  @changedValue="changeValue(props.row,column.name,$event)"
              />
            </q-popup-edit>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="showEditDialog">
    <ModalEditIdPage
        :id=currentIdEdit
        :current-editing-user=currentEditingRecord
        :columns="columns"
        :showEditDialog=showEditDialog
        :modelPage=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showEditDialog = false"
    ></ModalEditIdPage>
  </q-dialog>
</template>
<script>
// для использования в формах редактирования

//export default [validation]
</script>
<script setup>
import {computed, onMounted, ref} from 'vue'
import Popupedit from "@/components/Popupedit";
import ModalEditIdPage from "@/pages/singlePages/SingleIdPage";
import dataFunctions from "@/utils/dataFunctions"
import API from "@/utils/api";
import * as iconv from "iconv-lite";
import {Notify} from "quasar";

const columns = [

  { name: 'ip', label: 'IP', field: 'ip', align: 'left', sortable: true, editable: true, addable: true, visible: true,
    validation: {
      error: 'Введите корректный IP',
      rule: v => /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/i.test(v),
    }
  },
  { name: 'name', label: 'Название', field: 'name', align: 'left', sortable: true, required: true, editable: true, addable: true, visible: true,
    validation: {
      error: 'Введите коррректное название',
      rule: v => /^(.*){2,15}$/i.test(v)
    }
  },
  { name: 'server', label: 'Имя сервера', field: 'server', align: 'left', sortable: true, editable: true, addable: true, visible: true, type: 'autocomplete',
    validation: {
      error: 'Введите коррректное имя сервера',
      rule: v => /^[a-zA-Z\d_\-]{2,30}$/i.test(v)
    }
  },
  { name: 'geo', label: 'Гео', field: 'geo', align: 'left', sortable: true, editable: true, addable: true, visible: true, type: 'autocomplete',
    validation: {
      error: 'Введите коррректное ГЕО',
      rule: v => /^[a-zA-Z\d_\-]{2,15}$/i.test(v)
    }
  },
  { name: 'additional', label: 'Доп адрес', field: 'additional', align: 'left', sortable: true, editable: true, addable: true, visible: true, type: 'checkbox',
    validation: {
      error: '0 либо 1',
      rule: v => /^[0|1]$/i.test(v)
    }
  },
  { name: 'changed', label: 'Изменён', field: 'changed', align: 'left', sortable: true },
  {name: '#', label: '#', field: '#', align: 'left', sortable: false, searchable: false},
]
const modelPage = 'ips'
const loadingButton = ref({})
const tableRef = ref()
const showEditDialog = ref(false)
const currentIdEdit = ref(1)
const currentEditingRecord = ref({})
const loading = ref(false)
const rows = ref([])
const filter = ref('')
const pagination = ref({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 10
})
const {requestTableData, changeValue, changeData, confirmDelete} = dataFunctions(modelPage, loading, loadingButton )


async function requestData(props) {
  const tableData = {
    columns, pagination, rows
  }
  await requestTableData(props, tableData)
}

async function checkSsh(record) {
  try {
    loadingButton.value[record.id] = true
    loading.value = true
    // scope.hide()
    const response = await API.put(`/api/v1/${modelPage}/`, {
      id: record.id,
      checkSsh: true,
    })
    // обновляем все поля на фронте, которые могли прийти с сервера
    for (const rec in record) {
      record[rec] = response.data[rec]
    }
    loadingButton.value[record.id] = false
    loading.value = false
  } catch (reason) {
    iconv.skipDecodeWarning = true;
    reason.response.statusText = iconv.decode(reason.response.statusText, 'utf8');
    Notify.create({color: 'negative', multiLine: true, message: `${reason.response.status}: ${reason.response.statusText}`})
    //emit('closeDialog', true)
    alertDialogMessage.value = reason.response.data.error
    showAlertDialog.value = true
    loadingButton.value[record.id] = false
    loading.value = false
  } finally {
    loadingButton.value[record.id] = false
    loading.value = false
  }
}

const visibleColumns = computed(()=>{
  return columns.filter(a => a.visible !== false)
})

onMounted(() => {
  // get initial data from server (1st page)
  tableRef.value.requestServerInteraction()
})
</script>
