<template>
  <div class="q-pa-md">
    <div class="row items-center q-gutter-sm q-mb-md">
      <q-btn size="sm"
             @click="currentIdEdit=0; showEditDialog=true">
        <q-icon name="add"/>
      </q-btn>
    </div>
    <q-table
        :dense="$q.screen.gt.md"
        ref="tableRef"
        title="Картинки"
        :rows="rows"
        :columns="visibleColumns"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        @request="requestData"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" placeholder="Поиск">
          <template v-slot:append>
            <q-icon name="search"/>
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="column in visibleColumns" :key="column.id" class="text-left">
            <div v-if="column.type === 'checkbox'">
              <q-toggle size="xs"
                        :true-value="1"
                        :false-value="0"
                        v-model="props.row[column.name]"
                        @update:model-value="(val)=>changeValue(props.row,column.name,val)"/>
            </div>
            <div v-else-if="column.name==='image'"><img :src="props.row['url']" style="width: 75px;" ></div>
            <div v-else-if="column.name==='url'"><a :href="props.row['url']" target="_blank">{{ props.row[column.name] }}</a></div>
            <div v-else>{{ props.row[column.name] }}</div>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="currentIdEdit=props.row.id; currentEditingRecord=props.row; showEditDialog=true">
              <q-icon name="edit"/>
            </q-btn>
            <q-btn
                v-if="column.name==='#'"
                round
                dense
                size="xs"
                @click="confirmDelete(props.row,rows,tableRef)"
                :loading="loadingButton[props.row.id]">
              <q-icon name="delete"/>
            </q-btn>

            <q-popup-edit v-if="column.editable && column.type !== 'checkbox'" v-model="props.row[column.name]" v-slot="scope"
                          :validate="val => column.validation.rule(val)">
              <Popupedit
                  v-bind:scope="scope"
                  :column=column
                  :modelPage=modelPage
                  @changedValue="changeValue(props.row,column.name,$event)"
              />
            </q-popup-edit>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="showEditDialog">
    <ModalEditIdPage
        :id=currentIdEdit
        :current-editing-user=currentEditingRecord
        :columns="columns"
        :showEditDialog=showEditDialog
        :modelPage=modelPage
        @changedValue="changeData($event, rows)"
        @closeDialog="showEditDialog = false"
    ></ModalEditIdPage>
  </q-dialog>
</template>
<script>
// для использования в формах редактирования

//export default [validation]
</script>
<script setup>
import {computed, onMounted, ref} from 'vue'
import Popupedit from "@/components/Popupedit";
import ModalEditIdPage from "@/pages/singlePages/SingleIdPage";
import dataFunctions from "@/utils/dataFunctions"

const columns = [

  { name: 'name', label: 'Название', field: 'name', align: 'left', sortable: true, required: true, editable: true, addable: true, visible: true,
    validation: {
      error: 'Введите коррректное название',
      rule: v => /^[a-zA-Zа-яА-Я\d_\s\-]{2,15}$/i.test(v)
    }
  },
  { name: 'image', label: 'Картинка', field: 'image', align: 'left', sortable: true, editable: false, addable: false, visible: true, searchable: false},
  { name: 'url', label: 'URL', field: 'url', align: 'left', sortable: true, editable: false, addable: true, visible: true,
    validation: {
      error: 'Введите коррректный URL',
      rule: v => /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=\[\]]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()\[\]@:%_\+.~#?&//=]*)$/i.test(v)
    }
  },
  { name: 'changed', label: 'Изменён', field: 'changed', align: 'left', sortable: true },
  {name: '#', label: '#', field: '#', align: 'left', sortable: false, searchable: false},
]
const modelPage = 'images'
const tableRef = ref()
const showEditDialog = ref(false)
const currentIdEdit = ref(1)
const currentEditingRecord = ref({})
const loading = ref(false)
const loadingButton = ref({})
const rows = ref([])
const filter = ref('')
const pagination = ref({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 10
})
const {requestTableData, changeValue, changeData, confirmDelete} = dataFunctions(modelPage, loading, loadingButton )


async function requestData(props) {
  const tableData = {
    columns, pagination, rows
  }
  await requestTableData(props, tableData)
}

const visibleColumns = computed(()=>{
  return columns.filter(a => a.visible !== false)
})

onMounted(() => {
  // get initial data from server (1st page)
  tableRef.value.requestServerInteraction()
})
</script>
