<template>
  <div class="q-pa-none">
    <q-header>
      <q-toolbar class="bg-primary text-white q-my-none shadow-2">
        <q-btn stretch flat label="Главная" @click="$router.push('/')"/>
        <q-space/>
        <q-btn stretch flat label="Картинки" @click="$router.push('/images')"/>
        <q-btn stretch flat label="Домены" @click="$router.push('/domains')"/>
        <q-btn stretch flat label="Аккаунты" @click="$router.push('/accounts')"/>
        <q-btn stretch flat label="Сайты" @click="$router.push('/sites')"/>
        <q-btn stretch flat label="IPs" @click="$router.push('/ips')"/>
<!--        <q-btn-dropdown-->
<!--            flat-->
<!--            rounded-->
<!--            class="gt-xs"-->
<!--            label="Справочники">-->
<!--          <q-list>-->
<!--            <q-item clickable v-close-popup @click="$router.push('/stores')">-->
<!--              <q-item-section>-->
<!--                <q-item-label>Склады</q-item-label>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
<!--            <q-item clickable v-close-popup @click="$router.push('/kits')">-->
<!--              <q-item-section>-->
<!--                <q-item-label>Комплекты</q-item-label>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
<!--            <q-item clickable v-close-popup @click="$router.push('/materials')">-->
<!--              <q-item-section>-->
<!--                <q-item-label>Материалы</q-item-label>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
<!--            <q-item clickable v-close-popup @click="$router.push('/executors')">-->
<!--              <q-item-section>-->
<!--                <q-item-label>Исполнители</q-item-label>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
<!--            <q-item clickable v-close-popup @click="$router.push('/partners')">-->
<!--              <q-item-section>-->
<!--                <q-item-label>Заказчики</q-item-label>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
<!--          </q-list>-->
<!--        </q-btn-dropdown>-->

        <!--      <q-separator dark vertical />-->
        <q-btn
            flat
            rounded
            label="Пользователи"
            class="gt-xs"
            @click="$router.push('/users')"/>
        <span v-if="$store.state.user" class="gt-xs">{{ $store.state.user.name }} <q-icon name="logout"
                                                                                          @click="$router.push('/login')"
                                                                                          class="cursor-pointer"/></span>
        <!--      <q-btn stretch flat label="Таблица"  @click="$router.push('/table')"/>-->
        <q-btn
            flat
            round
            dense
            icon="menu"
            class="q-mr-sm xs"
            @click="leftDrawerOpen = !leftDrawerOpen"
        />
      </q-toolbar>
    </q-header>
    <q-drawer
        v-model="leftDrawerOpen"
        :width="200"
        overlay
        bordered
        class="bg-grey-3"

    >
      <q-scroll-area class="fit">
        === {{ $store.state.user.name }} ===
        <q-list>

          <template v-for="(menuItem, index) in menuList" :key="index">
            <q-item clickable :active="menuItem.label === 'Outbox'" v-ripple @click="$router.push(`/${menuItem.name}`)">
              <!--              <q-item-section avatar>-->
              <!--                <q-icon :name="menuItem.icon" />-->
              <!--              </q-item-section>-->
              <q-item-section>
                {{ menuItem.label }}
              </q-item-section>
            </q-item>
            <q-separator :key="'sep' + index" v-if="menuItem.separator"/>
          </template>
          <q-item clickable v-ripple @click="$router.push(`/login`)">
            <q-item-section>
              Выход
            </q-item-section>
          </q-item>

        </q-list>
      </q-scroll-area>
    </q-drawer>
  </div>
</template>

<script>
import {ref} from 'vue'
import Vue from 'vue'
import {useStore} from "vuex";

const menuList = [
  {
    name: 'users',
    icon: 'inbox',
    label: 'Пользователи',
    separator: true
  },
  {name: 'images',label: 'Картинки',},
  {name: 'domains',label: 'Домены',},
  {name: 'accounts',label: 'Аккаунты',},
  {name: 'sites',label: 'Сайты',},
  {name: 'ips',label: 'IP',},

]
export default {
  name: 'Navbar',
  setup() {
    return {
      model: ref('one'),
      leftDrawerOpen: ref(false),
      menuList
    }
  }
}
</script>

<style scoped>

</style>
